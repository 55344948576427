import React from 'react';
// import { MenuItem } from "react-bootstrap";
// import moment from 'moment';
import PropTypes from 'prop-types';
// import Datetime from 'react-datetime';
import { connect } from 'react-redux';
// import { UpdateUserDetails } from '../../../../src/redux/actions';
import { StyledHeader, ContainerV2 } from '../PeopleHomeV2/EditUserDetailsPopup/styles';
import { AboutTextBox, SaveAboutButton, StyledBody, StyledModalV2, TopInfo } from './styles';
import { putUpdateUserData } from '../../redux/actions';


class EditAboutPopup extends React.Component {

  constructor() {
    super();
    this.state = {
      bio: '',
      disableButtton: '',

      birthDate: ''
    }
  }

  disableButtonFunction = (value) => {
    this.setState({
      disableButtton: value
    })
  }

  updateUserProfile = () => {
    if(this.state.disableButtton != 'save_personal') {
      const { updateUserDataDetails, onClose } = this.props;
      const { bio } = this.state;
      const obj = {...this.props.userDetail};
      obj.bio = bio;
      obj.benef_fname ="";
      obj.benef_lname = "";
      obj.benef_relation = "";
      obj.benef_birth = "";
      obj.benef_phone = "";
      this.setState({
        disableButtton: 'save_personal'
      }, () => {
        updateUserDataDetails(obj, this.disableButtonFunction);
        onClose();
      })
    }
  };

  // onChangeInput = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  //   console.log(e.target.name)
  // };

  componentDidMount() {
    this.setState({
      bio : this.props.userDetail.bio,
    })
  }


  render() {
    const { showUserModal, onClose } = this.props;
    return (
      <StyledModalV2 show={showUserModal} style={{ "border": "none" }}>
        <StyledHeader /*closeButton*/>
          <ContainerV2 style={{ "justifyContent": "flex-start" }}>
            <div className="headerName">
              Add About You
            </div>
            <div className="close">
              <img
                src={"/public/images/new_close_icon.png"}
                onClick={() => onClose()}
              />
            </div>
          </ContainerV2>
        </StyledHeader>
        <StyledBody>
          <TopInfo>
            You can write about your years of experience, industry, or skills. People 
            also talk about their achievements or previous job experiences.
          </TopInfo>
          <AboutTextBox placeholder='Describe who you are' value={this.state.bio} onChange={(e) => e.target.value.length <= 150 && this.setState({ bio: e.target.value })}>
          </AboutTextBox>
          <SaveAboutButton>
            <button className='savebutton' onClick={this.updateUserProfile} disabled={this.state.disableButtton == 'save_personal'}>
              Save
            </button>
          </SaveAboutButton>
        </StyledBody>
      </StyledModalV2>
    );
  }
}

EditAboutPopup.propTypes = {
  showUserModal: PropTypes.bool,
  onClose: PropTypes.func,
  userDetail: PropTypes.object,
  updateUserDataDetails: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  updateUserDataDetails: (userData, callback) => dispatch(putUpdateUserData(userData, callback))
});

export default connect(null, mapDispatchToProps)((EditAboutPopup));